import { get } from './base';
var URL = '/site';
export var GetMain = function GetMain() {
  return get("".concat(URL, "/main/"));
};
export var GetSiteFaqsAPI = function GetSiteFaqsAPI() {
  return get("".concat(URL, "/faqs/"));
};
export var GetTermsAPI = function GetTermsAPI(terms_code) {
  return get("".concat(URL, "/terms/").concat(terms_code, "/"));
};
export var GetRentPlaceAPI = function GetRentPlaceAPI() {
  return get("".concat(URL, "/rent-place/"));
};
export var GetCompanyHistoriesAPI = function GetCompanyHistoriesAPI() {
  return get("".concat(URL, "/company-histories/"));
};
export var GetCompanyMediaNewsAPI = function GetCompanyMediaNewsAPI() {
  return get("".concat(URL, "/media-news/"));
};
export var GetCompanyFileAPI = function GetCompanyFileAPI() {
  return get("".concat(URL, "/company-introduction/"));
};